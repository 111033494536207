<template>
  <div>
    <div class="maintitle">公告列表</div>
    <el-divider></el-divider>
    <el-row class="text-left">
      <el-select
        v-model="crntdistrict"
        placeholder="请选择地区"
        style="width:120px;"
        class="margin-right"
      >
        <el-option
          v-for="(item,index) in district"
          :key="index"
          :label="item.fullname"
          :value="item.id"
        ></el-option>
      </el-select>
      <el-button @click="getlist">查看</el-button>
      <el-button type="success" class="margin-right" @click="addDiv=true">添加公告</el-button>
    </el-row>
    <el-table :data="list" border>
      <el-table-column align="center" prop="title" label="标题"></el-table-column>
      <el-table-column align="center" prop="update_time" label="最后修改时间"></el-table-column>
      <el-table-column align="center" prop="district_text" label="公告地区"></el-table-column>
      <el-table-column align="center" fixed="right" label="操作">
        <template slot-scope="scope">
          <el-button-group>
            <el-button size="small" @click="mdfy(scope)">修改</el-button>
            <el-button size="small" @click="del(scope)">删除</el-button>
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog :visible.sync="addDiv" width="80%">
      <el-form ref="form" :model="form" :rules="rules" label-width="60px">
        <el-form-item label="编号" prop="id" style="display:none">
          <el-input v-model="form.id" maxlength="20"></el-input>
        </el-form-item>
        <el-form-item label="标题" prop="title">
          <el-input v-model="form.title" minlength="4" maxlength="50" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="详情" prop="detail" style="height:300px" class="text-left">
          <div class="edit_container">
            <quill-editor
              v-model="form.detail"
              ref="myQuillEditor"
              class="editer"
              :options="editorOption"
              @ready="onEditorReady($event)"
              style="height:250px"
            ></quill-editor>
          </div>
        </el-form-item>
        <el-form-item label="修改地区" class="text-left">
          <el-checkbox-group v-model="form.arealist">
            <el-checkbox
              v-for="(item,index) in district"
              :key="index"
              :label="item.id+'-'+item.fullname"
              :value="item.id"
            >{{item.fullname}}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeAdd">取 消</el-button>
        <el-button type="primary" @click="add">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { quillEditor } from "vue-quill-editor";
// 富文本样式
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { addQuillTitle } from "@/plugins/quill-title.js";
export default {
  data() {
    return {
      list: [],
      crntdistrict: "", //当前选择县/区
      district: [], //县/区列表告
      formLabelWidth: "150px",
      form: {
        id: "", //公告id
        title: "", //公告标题
        detail: "", //公告内容
        arealist: [] //公告地区
      },
      addDiv: false,
      rules: {},
      crntgonggao: "",
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            // ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            // [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],
            // [{ size: ["small", false, "large", "huge"] }],
            // [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }],
            // [{ font: [] }],
            [{ align: [] }],
            ["clean"],
            ["image"]
          ]
        }
      }
    };
  },
  computed: {
    editor() {
      return this.$refs.myQuillEditor.quill;
    }
  },
  components: {
    quillEditor
  },
  methods: {
    onEditorReady(editor) {
      addQuillTitle();
    },
    closeAdd() {
      this.form.id = ""; //公告id
      this.form.title = ""; //公告标题
      this.form.detail = ""; //公告内容
      this.form.arealist = []; //公告地区
      this.crntgonggao = "";
      this.addDiv = false;
    },
    getlist: function() {
      if (this.crntdistrict == "") {
        this.$message({
          type: "error",
          message: "请选择地区"
        });
        return;
      }
      this.$axios({
        url: this.HOST + "/jiameng/gonggao/getGonggaoListByDistrict",
        method: "post",
        data: { district: this.crntdistrict }
      }).then(res => {
        if (res.data.status == 1) {
          this.list = res.data.list;
        }
      });
    },
    getAreaList() {
      let url = this.HOST + "/jiameng/index/getAreaList";
      this.$axios({
        method: "post",
        url: url,
        data: {}
      }).then(res => {
        if (res.data.status == 1) {
          this.district = res.data.list;
        } else {
          return [];
        }
      });
    },
    mdfy(scope) {
      this.$axios({
        url: this.HOST + "/jiameng/gonggao/getInfo",
        method: "post",
        data: { id: scope.row.id }
      }).then(res => {
        if (res.data.status == 1) {
          this.form.id = res.data.info.id; //公告id
          this.form.title = res.data.info.title; //公告标题
          this.form.detail = res.data.info.detail; //公告内容
          this.form.arealist = res.data.info.arealist; //公告地区
          this.crntgonggao = scope;
          this.addDiv = true;
        }
      });
    },
    add: function() {
      this.$axios({
        url: this.HOST + "/jiameng/gonggao/addGonggao",
        method: "post",
        data: {
          info: this.form
        }
      }).then(res => {
        if (res.data.status == 1) {
          if (this.form.id == "") {
            this.list = this.list.concat(res.data.info);
          } else {
            this.crntgonggao.row.title = res.data.info.title;
            this.crntgonggao.row.district_text = res.data.info.district_text;
            this.crntgonggao.row.detail = res.data.info.detail;
            this.crntgonggao.row.arealist = res.data.info.arealist;
            this.crntgonggao.row.update_time = res.data.info.update_time;
          }
          this.closeAdd();
        } else if (res.data.status == 0) {
          this.$message({
            type: "error",
            message: res.data.info
          });
        }
      });
    },
    del(scope) {
      this.$confirm("确定要删除公告吗？本操作不可恢复", "警告", {
        type: "waring",
        confirmButtonText: "确定",
        cancleButtonText: "取消"
      }).then(() => {
        this.$axios({
          method: "post",
          url: this.HOST + "/jiameng/gonggao/delGonggao",
          data: { id: scope.row.id }
        }).then(res => {
          if (res.data.status == 1) {
            this.list.splice(scope.$index, 1);
          }
        });
      });
    }
  },
  created: function() {
    this.getAreaList();
  }
};
</script>

<style>
.el-table .cell {
  white-space: pre-line;
}
</style>